var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        { staticClass: "highlight-part" },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.HighlightPart.title",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { handle: ".drag-handle" },
                      on: {
                        start: function($event) {
                          _vm.drag = true
                        },
                        end: function($event) {
                          _vm.drag = false
                        }
                      },
                      model: {
                        value: _vm.playroom.highlights,
                        callback: function($$v) {
                          _vm.$set(_vm.playroom, "highlights", $$v)
                        },
                        expression: "playroom.highlights"
                      }
                    },
                    _vm._l(_vm.$v.playroom.highlights.$each.$iter, function(
                      highlight,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "CCard",
                            [
                              _c(
                                "CCardBody",
                                [
                                  _c(
                                    "CContainer",
                                    [
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            {
                                              staticClass:
                                                "dragable drag-handle",
                                              attrs: { md: "1" }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cis-hamburger-menu"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "drag-handle",
                                              attrs: { md: "10" }
                                            },
                                            [
                                              _c("ValidatedTextArea", {
                                                ref: "txtName",
                                                refInFor: true,
                                                staticStyle: {
                                                  padding: "16px 16px 0px 16px"
                                                },
                                                attrs: {
                                                  field: highlight.name,
                                                  translationKey:
                                                    "playroomHighlight.name",
                                                  value: highlight.name.$model,
                                                  "data-cy": "name"
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      highlight.name,
                                                      "$model",
                                                      $event
                                                    )
                                                  },
                                                  focusChange: _vm.onFocusChange
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "middle-center",
                                              attrs: { md: "1" }
                                            },
                                            [
                                              _c("DeleteButton", {
                                                attrs: {
                                                  onDelete: function() {
                                                    return _vm.onDeletedHighlight(
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.stillAvailableCount > 0
                    ? _c(
                        "CButton",
                        {
                          staticClass: "add-button",
                          attrs: { color: "light", disabled: !_vm.canAdd },
                          on: { click: _vm.addNewHighlight }
                        },
                        [
                          _c("CIcon", { attrs: { name: "cilPlus" } }),
                          _c("span", { staticClass: "add-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.playroomEditParts.HighlightPart.addButton",
                                    {
                                      stillAvailableCount:
                                        _vm.stillAvailableCount
                                    }
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name: "components.playroomEditParts.HighlightPart.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }