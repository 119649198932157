var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-part" },
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.ImagePart.title",
                    ready: _vm.ready
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { handle: ".drag-handle" },
                      on: {
                        start: function($event) {
                          _vm.drag = true
                        },
                        end: function($event) {
                          _vm.drag = false
                        },
                        change: _vm.onDragChange
                      },
                      model: {
                        value: _vm.playroom.images,
                        callback: function($$v) {
                          _vm.$set(_vm.playroom, "images", $$v)
                        },
                        expression: "playroom.images"
                      }
                    },
                    [
                      _vm._l(_vm.$v.playroom.images.$each.$iter, function(
                        playroomImage,
                        index
                      ) {
                        return _c(
                          "CCard",
                          { key: playroomImage.id + index },
                          [
                            _c(
                              "CCardHeader",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.activeCollapsePlayroomIndex = index
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "dragable drag-handle" },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cis-hamburger-menu" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(
                                  " " + _vm._s(playroomImage.title.$model) + " "
                                ),
                                index == 0
                                  ? _c("span", {
                                      directives: [
                                        {
                                          name: "t",
                                          rawName: "v-t",
                                          value:
                                            "components.playroomEditParts.ImagePart.firstIndexInfo",
                                          expression:
                                            "'components.playroomEditParts.ImagePart.firstIndexInfo'"
                                        }
                                      ],
                                      staticClass: "header-info"
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "CCollapse",
                              {
                                attrs: {
                                  show: _vm.activeCollapsePlayroomIndex == index
                                }
                              },
                              [
                                _c(
                                  "CCardBody",
                                  [
                                    _c(
                                      "CRow",
                                      [
                                        _c(
                                          "CCol",
                                          {
                                            staticClass: "image-container",
                                            attrs: { md: "6" }
                                          },
                                          [
                                            _vm.showImageSpinner
                                              ? _c("CSpinner")
                                              : _vm._e(),
                                            !_vm.showImageSpinner
                                              ? _c("div", [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "file",
                                                      id: "file-input",
                                                      name: "file-input"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleFileInput
                                                    }
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "file-label",
                                                      attrs: {
                                                        for: "file-input"
                                                      }
                                                    },
                                                    [
                                                      playroomImage.dataUrl
                                                        .$model ||
                                                      playroomImage.link.$model
                                                        ? _c("img", {
                                                            staticClass:
                                                              "image",
                                                            attrs: {
                                                              src:
                                                                playroomImage
                                                                  .dataUrl
                                                                  .$model ||
                                                                playroomImage
                                                                  .link.$model,
                                                              alt:
                                                                playroomImage
                                                                  .title.$model
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !playroomImage.dataUrl
                                                        .$model &&
                                                      !playroomImage.link.$model
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("CIcon", {
                                                                attrs: {
                                                                  name:
                                                                    "cilCamera",
                                                                  size: "4xl"
                                                                }
                                                              }),
                                                              _c("br"),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-primary"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.PlayroomImageEditForm.uploadImage"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "CCol",
                                          { attrs: { md: "6" } },
                                          [
                                            _c("ValidatedInput", {
                                              attrs: {
                                                field: playroomImage.title,
                                                translationKey:
                                                  "playroomImage.title",
                                                value:
                                                  playroomImage.title.$model
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    playroomImage.title,
                                                    "$model",
                                                    $event
                                                  )
                                                },
                                                focusChange: _vm.onFocusChange
                                              }
                                            }),
                                            _c("ValidatedTextArea", {
                                              attrs: {
                                                field:
                                                  playroomImage.description,
                                                translationKey:
                                                  "playroomImage.description",
                                                value:
                                                  playroomImage.description
                                                    .$model,
                                                rows: "6"
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    playroomImage.description,
                                                    "$model",
                                                    $event
                                                  )
                                                },
                                                focusChange: _vm.onFocusChange
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "CCardFooter",
                                  [
                                    _c("SaveButton", {
                                      attrs: {
                                        model: playroomImage,
                                        onSave: function() {
                                          return _vm.handleSave(playroomImage)
                                        },
                                        saving: _vm.saving
                                      }
                                    }),
                                    _c("DeleteButton", {
                                      attrs: {
                                        onDelete: function() {
                                          return _vm.handleDelete(
                                            playroomImage.$model.id,
                                            index
                                          )
                                        },
                                        type: "text"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm.stillAvailableCount >= 1
                        ? _c(
                            "CButton",
                            {
                              staticClass: "add-button",
                              attrs: { color: "light" },
                              on: { click: _vm.addNewImage }
                            },
                            [
                              _c("CIcon", { attrs: { name: "cilPlus" } }),
                              _c("span", { staticClass: "add-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.playroomEditParts.ImagePart.add",
                                        {
                                          stillAvailableCount:
                                            _vm.stillAvailableCount
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("HelpCard", {
                attrs: {
                  focusFor: _vm.focusFor,
                  name: "components.playroomEditParts.ImagePart.info.box1"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }