var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.feature
    ? _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t(
              "components.playroomEditParts.FeaturePart.popupAddEditFeature"
            ),
            color: "warning",
            show: !!_vm.feature,
            centered: true,
            closeOnBackdrop: false
          },
          on: { "update:show": _vm.onCancel },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "CButton",
                      {
                        attrs: { color: "secondary", "data-testid": "cancel" },
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v(_vm._s(_vm.$t("forms.common.cancel")))]
                    ),
                    _c(
                      "CButton",
                      {
                        attrs: { color: "primary", "data-testid": "ok" },
                        on: { click: _vm.onOk }
                      },
                      [_vm._v(_vm._s(_vm.$t("forms.common.ok")))]
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3534047656
          )
        },
        [
          _c("ValidatedInput", {
            attrs: {
              field: _vm.$v.feature.customName,
              translationKey: "playroomFeature.customName",
              value: _vm.$v.feature.customName.$model
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.feature.customName, "$model", $event)
              }
            }
          }),
          _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.feature.hasFee,
              translationKey: "playroomFeature.hasFee",
              checked: _vm.$v.feature.hasFee.$model
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.feature.hasFee, "$model", $event)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }