var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.FeaturePart.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.FeaturePart.tooltip",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "components.playroomEditParts.FeaturePart.help",
                        expression:
                          "'components.playroomEditParts.FeaturePart.help'"
                      }
                    ]
                  })
                ],
                1
              ),
              _vm._l(_vm.$v.playroom.featureCategories.$each.$iter, function(
                category
              ) {
                return _c(
                  "div",
                  { key: category.categoryTranslationKey },
                  [
                    _c(
                      "CCard",
                      [
                        _c("CCardHeader", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "featureCategories." +
                                    category.$model.categoryTranslationKey
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "CCardBody",
                          [
                            _c(
                              "CRow",
                              _vm._l(category.features.$each.$iter, function(
                                feature
                              ) {
                                return _c(
                                  "CCol",
                                  {
                                    key: feature.$model.id,
                                    attrs: {
                                      md: "4",
                                      "data-featureTranslationKey":
                                        feature.$model.translationKey
                                    }
                                  },
                                  [
                                    _c("ValidatedCheckbox", {
                                      attrs: {
                                        field: feature.hasFeature,
                                        checked: feature.hasFeature.$model,
                                        label: _vm.$t(
                                          "features." +
                                            feature.$model.translationKey
                                        )
                                      },
                                      on: {
                                        "update:checked": function($event) {
                                          return _vm.$set(
                                            feature.hasFeature,
                                            "$model",
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "a",
                                      {
                                        attrs: { "data-testid": "switchFee" },
                                        on: {
                                          click: function($event) {
                                            return _vm.switchFee(feature.hasFee)
                                          }
                                        }
                                      },
                                      [
                                        feature.$model.hasFeature &&
                                        feature.$model.hasFee
                                          ? _c("CIcon", {
                                              staticClass: "fee-icon",
                                              attrs: {
                                                name: "cis-euro",
                                                size: "sm",
                                                title: _vm.feeMessage
                                              }
                                            })
                                          : _vm._e(),
                                        feature.$model.hasFeature &&
                                        !feature.$model.hasFee
                                          ? _c("CIcon", {
                                              staticClass: "fee-icon",
                                              attrs: {
                                                name: "cis-euro-slash",
                                                title: _vm.noFeeMessage
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("featureCategories.CustomFeatures")) +
                          " "
                      ),
                      _c("CIcon", {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              appendToBody: true,
                              content: _vm.$t(
                                "components.playroomEditParts.FeaturePart.CustomFeaturesInfo"
                              )
                            },
                            expression:
                              "{\n              appendToBody: true,\n              content: $t('components.playroomEditParts.FeaturePart.CustomFeaturesInfo'),\n            }"
                          }
                        ],
                        attrs: { name: "cis-info-circle" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CRow",
                        { staticClass: "custom-features-row" },
                        [
                          _vm._l(
                            _vm.$v.playroom.customFeatures.$each.$iter,
                            function(feature, index) {
                              return _c(
                                "CCol",
                                {
                                  key: feature.$model.id + index,
                                  attrs: {
                                    md: "4",
                                    "data-custom-feature":
                                      feature.$model.customName
                                  }
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "info",
                                        variant: "outline",
                                        "data-testid": "editButton"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openItemModal(feature)
                                        }
                                      }
                                    },
                                    [
                                      feature.$model.id === ""
                                        ? _c("CIcon", {
                                            attrs: {
                                              size: "sm",
                                              name: "cil-warning",
                                              title: "Nicht gespeichert"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(feature.$model.customName) +
                                          " "
                                      ),
                                      feature.$model.hasFee
                                        ? _c("CIcon", {
                                            staticClass: "fee-icon",
                                            attrs: {
                                              size: "sm",
                                              name: "cis-euro",
                                              title: _vm.feeMessage
                                            }
                                          })
                                        : _vm._e(),
                                      !feature.$model.hasFee
                                        ? _c("CIcon", {
                                            staticClass: "fee-icon",
                                            attrs: {
                                              size: "sm",
                                              name: "cis-euro-slash",
                                              title: _vm.noFeeMessage
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("CButtonClose", {
                                    attrs: {
                                      buttonClasses:
                                        "top-delete-btn text-danger close",
                                      "data-testid": "deleteButton"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return (function() {
                                          return _vm.openModal(index)
                                        })($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "4" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "add-button",
                                  attrs: {
                                    color: "light",
                                    "data-testid": "addNewCustomFeature"
                                  },
                                  on: { click: _vm.openNewItemModal }
                                },
                                [_c("CIcon", { attrs: { name: "cil-plus" } })],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.openModalForCustomPlayroomFeature
                ? _c("CustomFeatureModal", {
                    attrs: {
                      feature: _vm.openModalForCustomPlayroomFeature,
                      playroomId: _vm.playroomId
                    },
                    on: { ok: _vm.onOk, cancel: _vm.onCancel }
                  })
                : _vm._e(),
              _c("DeleteConfirmationModal", {
                attrs: { show: _vm.showModal, message: _vm.message },
                on: { delete: _vm.internalOnDelete, close: _vm.closeModal }
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value:
                      "components.playroomEditParts.FeaturePart.info.box1.title",
                    expression:
                      "'components.playroomEditParts.FeaturePart.info.box1.title'"
                  }
                ]
              }),
              _c("CCardBody", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.playroomEditParts.FeaturePart.info.box1.text"
                    )
                  )
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }