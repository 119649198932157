var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "room-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.RoomPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _vm.ready
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        {
                          attrs: { group: "rooms", handle: ".handle" },
                          on: {
                            start: function($event) {
                              _vm.drag = true
                            },
                            end: _vm.endDrag
                          },
                          model: {
                            value: _vm.playroom.rooms,
                            callback: function($$v) {
                              _vm.$set(_vm.playroom, "rooms", $$v)
                            },
                            expression: "playroom.rooms"
                          }
                        },
                        [
                          _c(
                            "transition-group",
                            _vm._l(_vm.$v.playroom.rooms.$each.$iter, function(
                              playroomRoom,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: playroomRoom.id + index },
                                [
                                  _c(
                                    "CCard",
                                    [
                                      _c(
                                        "CCardBody",
                                        [
                                          _c(
                                            "CRow",
                                            [
                                              _c(
                                                "CCol",
                                                {
                                                  staticClass:
                                                    "handle dragable",
                                                  attrs: { md: "1" }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cis-hamburger-menu"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "CCol",
                                                { attrs: { md: "11" } },
                                                [
                                                  _c(
                                                    "CRow",
                                                    [
                                                      _c(
                                                        "CCol",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c("ValidatedInput", {
                                                            attrs: {
                                                              field:
                                                                playroomRoom.name,
                                                              translationKey:
                                                                "playroomRoom.name",
                                                              value:
                                                                playroomRoom
                                                                  .name.$model
                                                            },
                                                            on: {
                                                              "update:value": function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  playroomRoom.name,
                                                                  "$model",
                                                                  $event
                                                                )
                                                              },
                                                              focusChange:
                                                                _vm.onFocusChange
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "CCol",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "ValidatedSelect",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  playroomRoom.roomType,
                                                                translationKey:
                                                                  "playroomRoom.roomType",
                                                                value:
                                                                  playroomRoom
                                                                    .roomType
                                                                    .$model,
                                                                options:
                                                                  _vm.roomTypeOptions
                                                              },
                                                              on: {
                                                                "update:value": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    playroomRoom.roomType,
                                                                    "$model",
                                                                    $event
                                                                  )
                                                                },
                                                                focusChange:
                                                                  _vm.onFocusChange
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "CCol",
                                                        { attrs: { md: "3" } },
                                                        [
                                                          _c("ValidatedInput", {
                                                            attrs: {
                                                              field:
                                                                playroomRoom.sizeInSquareMeters,
                                                              translationKey:
                                                                "playroomRoom.sizeInSquareMeters",
                                                              value:
                                                                playroomRoom
                                                                  .sizeInSquareMeters
                                                                  .$model,
                                                              append: "m²",
                                                              type: "number",
                                                              min: "1"
                                                            },
                                                            on: {
                                                              "update:value": function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  playroomRoom.sizeInSquareMeters,
                                                                  "$model",
                                                                  $event
                                                                )
                                                              },
                                                              focusChange:
                                                                _vm.onFocusChange
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "CCol",
                                                        {
                                                          staticClass:
                                                            "middle-center",
                                                          attrs: { md: "1" }
                                                        },
                                                        [
                                                          _c("DeleteButton", {
                                                            attrs: {
                                                              onDelete: function() {
                                                                return _vm.onDeletedPlayroomRoom(
                                                                  index
                                                                )
                                                              },
                                                              message: _vm.$t(
                                                                "components.playroomEditParts.PlayroomRoomEditForm.deleteMessage"
                                                              )
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _vm.stillAvailableCount > 1
                                ? _c(
                                    "CButton",
                                    {
                                      staticClass: "add-button",
                                      attrs: { color: "light" },
                                      on: { click: _vm.addNewRoom }
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cilPlus" }
                                      }),
                                      _c("span", { staticClass: "add-text" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.playroomEditParts.RoomPart.addText",
                                                {
                                                  stillAvailableCount:
                                                    _vm.stillAvailableCount
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.RoomPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }